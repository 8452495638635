.wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.experienceLinkWrapper {
  align-self: center;
  max-width: 70%;
}

.experienceLink {
  text-decoration: none;
}